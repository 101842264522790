import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";


i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ["en", "ar", "ku", "swe"],
        fallbackLng: "en",
        debug: false,

        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
    });


export default i18n;