import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "dark",
};

const darkLightThemeSlice = createSlice({
    name: "darkLightTheme",
    initialState,
    reducers: {
        toggleTheme: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        }
    },
});

export const { toggleTheme } = darkLightThemeSlice.actions;
export default darkLightThemeSlice.reducer;