import React, { useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageWrapper from './components/language/LanguageWrapper';
import { themeSettings } from './data/themes/colorCodes';
import { useSelector } from 'react-redux';
import { LanguageSelect } from './pages/LanguageSelectPage';
import { Login } from './pages/LoginPage';
import { Register } from './pages/RegisterPage';


function App() {
  const mode = useSelector((state: any) => state.darkLightTheme.mode || "light")
  const [auth,] = useState(false)

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    return auth ? children : <Navigate to="/login" />;
  };

  const LoggedInAuth = ({ children }: { children: JSX.Element }) => {
    return auth ? <Navigate to="/" /> : children;
  };

  const LangSelectOrPanel = () => {
    return auth ? <div>panel</div> : <LanguageSelect />
  }

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

  const backgroundColor = theme.palette.background.default

  const { t } = useTranslation();

  return (
    <Box className="App" sx={{ backgroundColor: backgroundColor }}>
      <LanguageWrapper>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={LangSelectOrPanel()} />
              {/* <Route path="/panel" element={<div></div>} />
              <Route path="/login" element={<LoggedInAuth><Login /></LoggedInAuth>} />
              <Route path="/register" element={<LoggedInAuth><Register /></LoggedInAuth>} />

              <Route path="/profile/:userId" element={<RequireAuth><div>profile</div></RequireAuth>} />
              <Route path="/profile/:userId/listings" element={<RequireAuth><div>user listings</div></RequireAuth>} />
              <Route path="/profile/:userId/bookings" element={<RequireAuth><div>user bookings</div></RequireAuth>} />

              <Route path="/listings/:listingId" element={<RequireAuth><div>listing</div></RequireAuth>} />
              <Route path="/listings/:listingId/bookings" element={<RequireAuth><div>all bookings</div></RequireAuth>} />
              <Route path="/listings/:listingId/bookings/:bookingId" element={<RequireAuth><div>booking</div></RequireAuth>} /> */}
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </LanguageWrapper>
    </Box>
  );
}

export default App;
