import React, { useEffect, useMemo, useState } from "react";
import "../../index.css";
import { useTranslation } from "react-i18next";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { languages } from "./languageFile";

const LanguageWrapper = ({ children }: any) => {
  const { i18n } = useTranslation();
  const langCode = useMemo(
    () => languages.find((l) => l.code === i18n.language)?.code,
    [i18n.language]
  );
  const [dir, setDir] = useState(
    i18n.dir(langCode) || languages.find((l) => l.code === i18n.language)?.dir
  );


  useEffect(() => {
    document.dir = dir;
  }, [dir]);

  useEffect(() => {
    if (i18n.language === "ar" || i18n.language === "ku") {
      setDir("rtl");
    } else {
      setDir("ltr");
    }
  }, [i18n.language]);

  const theme = useMemo(() => {
    return createTheme({
      direction: dir,
    });
  }, [dir]);

  const cacheRtl = useMemo(() => {
    if (dir === "rtl") {
      return createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin],
      });
    } else {
      return createCache({ key: "css" });
    }
  }, [dir]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
};

export default LanguageWrapper;
