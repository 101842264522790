export interface ILanguage {
  code: string;
  name: string;
  dir: string;
  country_code: string;
}

export const languages: ILanguage[] = [
  {
    code: "en",
    name: "English",
    dir: "ltr",
    country_code: "gb",
  },
  {
    code: "ku",
    name: "کوردی",
    dir: "rtl",
    country_code: "iq",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "iq",
  },
  // {
  //   code: "swe",
  //   name: "Svenska",
  //   dir: "ltr",
  //   country_code: "se",
  // },
];
