import { rootReducer } from "../reducers";
import { configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { firebaseApp } from "./persistance/firebase";
import { getUserAuthStatus } from "./persistance/firebase";

const persistConfig = { key: "root", storage, version: 1 };
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], },
        }),
});
const persistor = persistStore(store);

store.dispatch(getUserAuthStatus()) //makes sure that if the user account no longer exists, for instance, it will get logged out

// persistance(store, firebaseApp)


export { store, persistor };