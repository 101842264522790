import { toggleTheme } from "../../../reducers/darkLightTheme";

import { useDispatch, useSelector } from "react-redux";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

export const ThemeToggle = () => {
    const dispatch = useDispatch();
    const mode = useSelector((state: any) => state.darkLightTheme.mode);

    const theme = useTheme()

    const showIcon = (type: string) => {
        if (type === "light" && mode !== "light") {
            return "none"
        }
        else if (type === "dark" && mode !== "dark") {
            return "none"
        }
    }

    return (

        <Box>
            <IconButton onClick={() => dispatch(toggleTheme())} >
                <Fade timeout={200} in={mode === "dark"} style={{ display: showIcon("dark") }}>
                    <LightModeIcon sx={{ fontSize: "2em", color: "white" }} />
                </Fade>
                <Fade timeout={200} in={mode === "light"} style={{ display: showIcon("light") }}>
                    <DarkModeIcon sx={{ fontSize: "2em", color: "black" }} />
                </Fade>
            </IconButton>
        </Box>

    );

}