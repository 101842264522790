import { Box, Button, Grid, createTheme } from '@mui/material';
import { HouzyGem } from '../components/start-page/HouzyGem';
import { ThemeToggle } from '../components/language/lightDarkTheme/themeToggle';
import transparentLogo from "../data/images/transparent_logo.png"
import { LanguageSelector } from '../components/language/languageSelector';
import { useMemo } from 'react';
import { themeSettings } from '../data/themes/colorCodes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const LanguageSelect = () => {
  const mode = useSelector((state: any) => state.darkLightTheme.mode || "light")
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])

  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="center" height="90vh">
      {/* <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-end" alignContent="flex-start" alignItems="center" spacing={1} >
          <Grid item>
            <Button variant="contained" color="primary" href="/login">Login</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" href="/register">Register</Button>
          </Grid>
          <Grid item>
            <ThemeToggle />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8} height="100%">
        <Grid container height="100%" direction="row" justifyContent="space-around" alignItems="center" alignContent="center" >
          <Grid item md={6} xs={9} sx={{ md: { height: "60%" }, lg: { height: "80%" } }}>
            <Grid container direction="column" justifyContent="center" height="100%" spacing={2}>
              <Grid item xs={3}>
                <Box component="img"
                  sx={{
                    width: "80%"
                  }}
                  src={transparentLogo}
                  alt="houzy gem"
                />
              </Grid>
              <Grid item>
                <LanguageSelector />
              </Grid>
            </Grid>

          </Grid>
          <Grid item md={5} xs={6} mt={5}>
            <HouzyGem />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="flex-end" alignContent="flex-start" alignItems="center" spacing={1} >

          <Grid item>
            <ThemeToggle />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box component="img"
          sx={{
            width: "50%"
          }}
          src={transparentLogo}
          alt="houzy gem"
        />
        <h1>{t("comingsoon")}</h1>
      </Grid>
    </Grid>
  );
};